import doc, { Document } from '@/shared/model/document';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FileUtils from '@/shared/utils/fileUtils';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import {
  RequestType,
  ThreadType,
} from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-qa/thread-request-qa-types';
import { ActionLog } from '@/shared/model/actionLog';
import ActionLogsHelper from '@/shared/utils/actionLogHelper';
import { add } from 'date-fns';
import GobdHelper from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-gobd/gobdHelper';

const logger = new Logger('list-item-qa-section');

@Component({ name: 'list-item-qa-section-compact', components: {} })
export default class ListItemQaSectionCompact extends Vue {
  @Prop({ default: () => doc.parse({}) })
  file?: Document;

  get hasQuestionDate(): boolean {
    return this.OpenQuestionDate ? true : false;
  }

  get hasTaskDate(): boolean {
    return this.OpenTaskDate ? true : false;
  }

  get hasCircularDate(): boolean {
    return this.OpenCircularDate ? true : false;
  }

  get hasActiveReminders(): boolean {
    return !!this.file?.hasActiveReminders;
  }

  get hasAccounting(): boolean {
    return !!this.file?.haveAllocations;
  }

  get isGobdGreen(): boolean {
    return !!GobdHelper.checkIfGobdGreen(this.file);
  }

  get isGobdBlue(): boolean {
    return !!GobdHelper.checkIfGobdBlue(this.file);
  }

  get isGobdYellow(): boolean {
    return !!GobdHelper.checkIfGobdYellow(this.file);
  }

  get OpenQuestionDate() {
    return ActionLogsHelper.getOpenQuestionDate(this.file);
  }
  get OpenQuestionId() {
    return ActionLogsHelper.OpenQuestionId(this.file?.actionLogs);
  }
  get ClosedQuestionDate() {
    return ActionLogsHelper.getClosedQuestionDate(this.file);
  }

  get ClosedQuestion() {
    return ActionLogsHelper.getClosedQuestionId(this.file);
  }

  get ClosedQuestionId() {
    return ActionLogsHelper.getClosedQuestionId(this.file);
  }
  get OpenTaskDate() {
    return ActionLogsHelper.getOpenTaskDate(this.file);
  }
  get OpenTaskId() {
    return ActionLogsHelper.getOpenTaskId(this.file);
  }
  get ClosedTaskDate() {
    return ActionLogsHelper.getClosedTaskDate(this.file);
  }
  get ClosedTaskId() {
    return ActionLogsHelper.getClosedTaskId(this.file);
  }
  get OpenCircularDate() {
    return ActionLogsHelper.getOpenCircularDate(this.file);
  }
  get OpenCircularId() {
    return ActionLogsHelper.getOpenCircularId(this.file);
  }
  get ClosedCircularDate() {
    return ActionLogsHelper.getClosedCircularDate(this.file);
  }
  get ClosedCircularId() {
    return ActionLogsHelper.getClosedCircularId(this.file);
  }

  formattedDate(date: string): string {
    if (this.$i18n.locale.toLowerCase() === 'de' && date) {
      return DateUtils.isoDateToDotDate(date);
    }
    return date;
  }

  //#region Check expiration date
  get isQuestionDateExpired() {
    return this.OpenQuestionDate && this.isDateExpired(this.OpenQuestionDate);
  }
  get isTaskDateExpired() {
    return this.OpenTaskDate && this.isDateExpired(this.OpenTaskDate);
  }
  get isCircularDateExpired() {
    return this.OpenCircularDate && this.isDateExpired(this.OpenCircularDate);
  }

  private todayDateIso = new Date().toISOString().slice(0, 10);
  private isDateExpired(date: any) {
    if (date.includes('.')) {
      date = DateUtils.dotDateToIsoDate(date)!;
    }
    return new Date(date) < new Date(this.todayDateIso);
  }

  private isDateExpiredAfterToday(date: any, days: number) {
    if (date.includes('.')) {
      date = DateUtils.dotDateToIsoDate(date)!;
    }

    const dateEnd = new Date(date);
    dateEnd.setDate(dateEnd.getDate() + days);

    return new Date(dateEnd) < new Date(this.todayDateIso);
  }

  //#endregion
  get isAllQuestionDateClosed() {
    return !this.OpenQuestionDate && this.ClosedQuestionDate;
  }

  get isAllQuestionClosed() {
    return ActionLogsHelper.IsAllQuestionClosed(this.file);
  }

  get isAllTaskDateClosed() {
    return !this.OpenTaskDate && this.ClosedTaskDate;
  }

  get isAllTaskClosed() {
    return ActionLogsHelper.IsAllTaskClosed(this.file);
  }

  get isAllCircularDateClosed() {
    return !this.OpenCircularDate && this.ClosedCircularDate;
  }

  get isAllCircularClosed() {
    return ActionLogsHelper.IsAllCircularClosed(this.file);
  }

  //#region ED-944 QA (Frage & Antworten logic)
  private qaTypeMapping: any = {
    Frage: ThreadType.question,
    Aufgabe: ThreadType.task,
    Rundschreiben: ThreadType.circular,
  };

  openQuestionDialog() {
    this.$emit('open:qa-dialog', this.OpenQuestionId, this.file);
  }
  openQaQuestionDialog(startMessageId: string) {
    this.$router.push(`/document/${this.file?.id}/qa-question/${startMessageId}`);
  }
  openQaTaskDialog(startMessageId: string) {
    this.$router.push(`/document/${this.file?.id}/qa-task/${startMessageId}`);
  }
  openQaCircularDialog(startMessageId: string) {
    this.$router.push(`/document/${this.file?.id}/qa-circular/${startMessageId}`);
  }

  openQaOverview(startMessageId: string) {
    this.$emit('open:qa-overview', startMessageId, this.file);
  }
  openQa(qa: Document, type: 'task' | 'question' | 'circular') {
    switch (type) {
      case 'circular':
        if (qa.threadCircular.actionLogId && qa.threadCircular.closed == true)
          this.openQaOverview(qa.threadCircular.actionLogId);
        else this.openQaCircularDialog(qa.threadCircular.actionLogId);
        break;
      case 'task':
        if (qa.threadTask.actionLogId && qa.threadTask.closed == true) this.openQaOverview(qa.threadTask.actionLogId);
        else this.openQaTaskDialog(qa.threadTask.actionLogId);
        break;
      case 'question':
        if (qa.threadQuestion.actionLogId && qa.threadQuestion.closed == true)
          this.openQaOverview(qa.threadQuestion.actionLogId);
        else this.openQaQuestionDialog(qa.threadQuestion.actionLogId);
        break;
      default:
        break;
    }
  }

  get documentHasOpenQuestion() {
    return ActionLogsHelper.HasOpenQuestion(this.file);
  }
  get documentHasOpenTask() {
    return ActionLogsHelper.HasOpenTask(this.file);
  }
  get documentHasOpenCircular() {
    return ActionLogsHelper.HasOpenCircular(this.file);
  }
  //#endregion
}
