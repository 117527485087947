import { DocumentStateState } from './../../shared/store/modules/documentState/types';
import doc, { Document } from '@/shared/model/document';
import file, { File } from '@/shared/model/file';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FileUtils from '@/shared/utils/fileUtils';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import GobdHelper from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-gobd/gobdHelper';
import DateUtils from '@/shared/utils/DateUtils';
import {
  RequestType,
  ThreadType,
} from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-qa/thread-request-qa-types';
import { ActionLog } from '@/shared/model/actionLog';
import { OdataItems } from '@/shared/model/OdataItems';
import userFile, { UserFile } from '@/shared/model/userFile';
import TreeUtils from '@/shared/utils/treeUtils';
import RouterUtils from '@/shared/utils/RouterUtils';

import ListItemQaSection from './list-item-qa-section/list-item-qa-section.vue';
import ListItemQaSectionCompact from './list-item-qa-section-compact/list-item-qa-section-compact.vue';
import { DocumentStatus, DocumentStatusValues } from '@/shared/model/documentStatus';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import { DocumentProperty } from '@/shared/model/documentProperty';
import i18n from '@/i18n';
import documentEditSession, { DocumentEditSession } from '@/shared/model/documentEditSession';
import { Route } from 'vue-router';

const logger = new Logger('document-list-item');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const documentModule = namespace('document');
const documentStateModule = namespace('documentState');
const documentFileModule = namespace('documentFile');
const documentPropertyModule = namespace('documentProperty');
const organisationDocumentPropertyModule = namespace('organisationDocumentProperty');
const folderModule = namespace('folder');
const userFileModule = namespace('userFile');
const authModule = namespace('auth');
const documentEditSessionModule = namespace('documentEditSession');

@Component({ name: 'document-list-item', components: { ListItemQaSection, ListItemQaSectionCompact } })
export default class DocumentListItem extends Vue {
  @organisationDocumentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private organisationDocumentPropertys!: OdataItems<OrganisationDocumentProperty>;
  @documentPropertyModule.Getter('getDocumentPropertysEnabledFavorite')
  private getDocumentPropertysEnabledFavorite!: (
    dp: DocumentProperty[],
    o: OdataItems<OrganisationDocumentProperty>,
    i: number
  ) => {};
  @userFileModule.Getter('getUserFileIsLoadingById')
  private previewIsLoading!: (id: string) => {};
  @folderModule.Getter('getColor')
  private getColor!: (id: string) => {};
  @documentModule.Getter('getDocuments')
  private documents!: OdataItems<Document>;
  @documentStateModule.Getter('getStateId')
  private stateId!: (name: string, ds?: { name: string; id: string; updatedAt?: Date }[]) => string;
  @documentModule.Getter('getDocumentsIsLoading')
  private documentsIsLoading!: boolean;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @folderModule.Getter('getMenuFoldersTreeViewFlat')
  private folders!: any;
  @documentModule.Getter('isDocumentPropertiesLoadingForDocument')
  isDocumentPropertiesLoadingForDocument!: any;

  @userFileModule.Action('getUserFilePreview')
  private getUserFilePreview!: any;

  @authModule.Getter('getAccount')
  private account!: any;
  @authModule.Getter('isRightViewAllowed')
  private isRightViewAllowed!: (x: string) => {};

  @documentEditSessionModule.Action('getDocumentEditSession')
  private getDocumentEditSession!: any;

  @Prop({ default: () => doc.parse({}) })
  file?: Document;

  @Prop({ default: () => [] })
  values?: Array<DocumentStatus>;
  get visibleStatuses() {
    return this.values?.filter((x) => this.isRightViewAllowed(x.statusValue));
  }
  @Prop({ default: false, type: Boolean })
  private selectionMode!: boolean;

  get enabledFavoriteValue1() {
    return this.enabledFavoriteValue(0);
  }
  get enabledFavoriteValue2() {
    return this.enabledFavoriteValue(1);
  }
  get enabledFavoriteValue3() {
    return this.enabledFavoriteValue(2);
  }
  enabledFavoriteValue(i: number) {
    if (this.organisationDocumentPropertys?.items?.length > 0)
      return this.getDocumentPropertysEnabledFavorite(
        this.file?.documentPropertys ?? [],
        this.organisationDocumentPropertys,
        i
      );
    return undefined;
  }
  get imagePreviewIsLoading() {
    return this.previewIsLoading(this.file?.previewFileId ?? '');
  }
  get imagePreview(): string | undefined {
    if (!this.file?.imagePreview) return undefined;
    return this.file.imagePreview;
  }

  private timeoutID!: ReturnType<typeof setTimeout>;
  showMiniPreview(e: MouseEvent, x: any) {
    this.timeoutID = setTimeout(() => {
      const element = document.getElementById(x);
      if (element) element.style.display = 'initial';
    }, 2000);
  }

  hideMiniPreview(e: MouseEvent, x: any) {
    document.getElementById(x)!.style!.display = 'none';
    clearTimeout(this.timeoutID);
  }

  clickMiniPreview(e: MouseEvent, x: string) {
    document.getElementById(x)!.style!.display = 'none';
    clearTimeout(this.timeoutID);
    this.$emit('click:previewpdf', x);
  }

  //#region (ED-673) Format `amount` Number based on locale with `2 decimal places` and `grouping`
  addZeroes(num: any) {
    if (num == '0') num = 0;
    return num.toLocaleString(this.$i18n.locale.toLowerCase(), { useGrouping: true, minimumFractionDigits: 2 });
  }

  get hasGobdAmount() {
    if (this.file?.documentStateHasGobdAmount == 0) return true;
    return !!this.file?.documentStateHasGobdAmount; //https://stackoverflow.com/questions/784929/what-is-the-not-not-operator-in-javascript
  }

  get hasNegativeGobdAmount() {
    const amount = this.file?.documentStateHasGobdAmount;
    if (amount != undefined) {
      return amount < 0;
    }
  }

  get formattedGobdAmount() {
    return this.addZeroes(this.file?.documentStateHasGobdAmount ?? 0); // getter in `v-if` so if no GOBD data then this getter WILL NOT be called
  }
  //#endregion

  get isMultipage() {
    return this.file!.pageCount > 1;
  }

  get hasAddressData() {
    return this.file?.adresseKundennummer !== undefined || this.file?.adresseKennzeichen !== undefined;
  }

  private isSelected = false;

  //private imagePath = require(`@/assets/extension_icons/${this.fileExtension}_icon@2x.png`);

  get fileExtension() {
    return this.file?.name ? FileUtils.getFileExtension(this.file!.name).toUpperCase() : '';
  }

  isActiveBackendStatus(status: DocumentStatus) {
    let id: string;
    switch (status.statusValue) {
      case 'contest':
        return this.file?.haveAllocations;
      // case DocumentStatusValues.New:
      //   case DocumentStatusValues.NewInFolder:
      case DocumentStatusValues.Payed:
        id = this.file?.documentStatePayed ? 'd' : '';
        break;
      case DocumentStatusValues.Accepted:
        id = this.file?.documentStateAccepted ? 'd' : '';
        break;
      case DocumentStatusValues.Allocated:
        id = this.file?.documentStateAllocated ? 'd' : '';
        break;
      case DocumentStatusValues.Documented:
        id = this.file?.documentStateDocumented ? 'd' : '';
        break;
      case DocumentStatusValues.FinalCheck:
        id = this.file?.documentStateFinalCheck ? 'd' : '';
        break;
      case DocumentStatusValues.Hasgobd:
        id = this.file?.documentStateHasGobd ? 'd' : '';
        break;
      case DocumentStatusValues.Extra1:
        id = this.file?.documentStateExtra1 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra2:
        id = this.file?.documentStateExtra2 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra3:
        id = this.file?.documentStateExtra3 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra4:
        id = this.file?.documentStateExtra4 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra5:
        id = this.file?.documentStateExtra5 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra6:
        id = this.file?.documentStateExtra6 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra7:
        id = this.file?.documentStateExtra7 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra8:
        id = this.file?.documentStateExtra8 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra9:
        id = this.file?.documentStateExtra9 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra10:
        id = this.file?.documentStateExtra10 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra11:
        id = this.file?.documentStateExtra11 ? 'd' : '';
        break;
      case DocumentStatusValues.Extra12:
        id = this.file?.documentStateExtra12 ? 'd' : '';
        break;
        // id = this.stateId(status.statusValue, this.file?.documentStates);
        break;
      default:
        id = '';
        break;
    }
    return id != '';
  }

  allGobdFilled(item: any) {
    return item === 'GoBD Prüfung' && GobdHelper.checkIfAllGobdBackendFieldsFilledForDocument(this.file);
  }

  documentHasGobd(item: any) {
    return item === 'GoBD Prüfung' && !!this.file?.documentGobdDatum?.id;
  }

  documentGobdYellow(item: any) {
    return item === 'GoBD Prüfung' && GobdHelper.checkIfGobdYellow(this.file);
  }

  documentGobdGreen(item: any) {
    return item === 'GoBD Prüfung' && GobdHelper.checkIfGobdGreen(this.file);
  }

  documentGobdBlue(item: any) {
    return item === 'GoBD Prüfung' && GobdHelper.checkIfGobdBlue(this.file);
  }

  openDocumentNotes() {
    this.$router.push(`/document/${this.file?.id}?openNotes=true`);
  }

  documentHasNotes(item: any) {
    return item === 'Notiz' && !!this.file?.documentHasNotes;
  }

  deleteFromDatabaseDate() {
    const isoDate = new Date(this.file!.realDeleteAfter! * 1000).toISOString();
    return DateUtils.dotDateTimeFromIsoDateTime(isoDate, ' um');
  }

  private handleClick() {
    if (!this.selectionMode) {
      this.goToFilePreview();
    } else {
      this.selectItem();
    }
  }

  created() {
    // (ED-450) before introducing `v-data-iterator` for paging the component structure was simple `document-list-item` -> `document-list` (where `document-list` was parent), but with introducing the `v-data-iterator` the component structure became `document-list-item` -> `v-data` -> `v-data-iterator` -> `document-list` (so `document-list` from parent became great-grandparent OR parent of grandparent), so that is why we need `this.$parent.$parent.$parent`
    // TODO: (ED-450) during refactoring setup Event bus instead of `this.$parent.$parent.$parent`
    this.$parent.$parent.$parent.$on('update:selected', this.setSelected);
  }

  documentExtraStatusesOld: any = [];
  async mounted() {
    await this.loadInitData();
  }

  private async loadInitData() {
    // this.documentExtraStatusesOld = this.getOrganisationDocumentExtraStatusSettings.items;
  }

  private setSelected(value: boolean) {
    this.isSelected = value;
  }

  private selectItem() {
    this.isSelected = !this.isSelected;
    const payload = {
      id: this.file?.id,
      isSelected: this.isSelected,
    };

    this.$emit('click:document', payload);
  }

  private goToFilePreview() {
    this.getDocumentEditSession(this.file!.id).then((response: DocumentEditSession) => {
      if (response.isCurrentUser || !response) {
        this.openDocumentWithUserId(this.file!.id);
      } else {
        const user = response.fullUserName;
        this.$confirm
          .open(
            `${this.$t('dialogs.already_opened.title')}`,
            `${this.$t('dialogs.already_opened.message', {
              0: this.file!.name,
              1: this.file!.documentNumber,
              2: user,
            })}`,
            {
              cancelText: this.$t('dialogs.already_opened.cancel'),
              okText: this.$t('dialogs.already_opened.open'),
            }
          )
          .then(async (response: any) => {
            if (response) {
              this.openDocumentWithUserId(this.file!.id);
            } else {
            }
          });
      }
    });
  }

  openDocumentWithUserId(id: string) {
    this.$router.push({
      name: 'document-preview',
      params: { documentId: id },
    });
  }

  private getFolderPath(document: Document) {
    const filderPath = TreeUtils.getFolderPath(document?.folderId, this.folders);
    return filderPath;
  }

  private hasPreviewId(preview: any) {
    const hasPreviewSizeAndId = preview && preview.file.fileSize > 0 && preview.fileId; // avoid request for documents without previews, with 0 size + fix strange console error `possible `preview.fileId` duplicate
    return hasPreviewSizeAndId || this.file?.previewFileId !== RouterUtils.emptyGuid;
  }

  private isIntersecting: boolean = false;
  async onIntersect(entries: any, observer: any) {
    // More information about these options
    // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.isIntersecting = entries[0].isIntersecting;

    if (this.isIntersecting) {
      const preview = this.file?.documentFiles?.find((x) => x.file?.kind == 'file preview image');

      if (!this.file?.imagePreview) {
        const fileId = preview?.fileId ? preview?.fileId : this.file!.previewFileId;
        await this.getUserFilePreview(fileId);
      }
    }
  }

  get isOwnerDocument() {
    let result = false;
    const openedQAs = this.file?.actionLogs?.filter((x) => x.closed == false);
    openedQAs?.forEach((x) => {
      if (this.isObject(x.data)) {
        const zz = (x.data as any).memberIds;
        if (zz.includes(this.account.profile.sub)) result = true;
      } else {
        // let jsonData = JSON.parse(x.data);
        if (x.data['memberIds'].includes(this.account.profile.sub)) result = true;
      }
    });
    return result;
  }

  //https://stackoverflow.com/questions/11182924/how-to-check-if-javascript-object-is-json
  private isObject(obj: any) {
    return obj !== undefined && obj !== null && obj.constructor == Object;
  }

  private concatenateAndTruncateIfNeeded(text1: string, text2: string, maxLength: number, truncate: boolean) {
    const text = [text1, text2].filter(Boolean).join(', ');
    if (!truncate) return text;
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + '...';
  }

  private ocrIconTitle(text: string, mode: string) {
    if (!text) return this.$i18n.t('ocr_status_not_ready').toString();
    return (
      DateUtils.isoDateToScreenDateWithLocale(text, this.$i18n.locale) + ' - ' + this.ocrMode(mode)
      //this.$i18n.t('ocr_status_ready').toString()
    );
  }

  private ocrMode(text: string) {
    switch (text) {
      case 'manual':
        return this.$i18n.t('ocr_mode_manual').toString();

      case 'pdftotext':
      case '':
        return this.$i18n.t('ocr_status_ready').toString();

      case 'dev4you-webhook':
        return this.$i18n.t('ocr_mode_recreated').toString();
    }
  }

  private ocrIconColor(text: string) {
    if (!text) return 'red';
    return 'green';
  }

  formatDate(date: string) {
    return DateUtils.formatDateFromUtcNowToDisplayWithTimeZone(date, this.$i18n.locale.toLowerCase(), false);
  }
}
