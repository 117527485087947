var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-item-qa-section__container"},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"px-1 text-uppercase",class:{
        'gobd-section--is-yellow-green': _vm.isGobdGreen, 
              'gobd-section--is-yellow': _vm.isGobdYellow, 
                'gobd-section--is-blue': _vm.isGobdBlue
       },attrs:{"title":_vm.$t('gobd_title')}},[_c('span',[_vm._v(_vm._s(_vm.$t('gobd')))])]),_c('div',{staticClass:"px-1 text-uppercase",class:{
        'accounting-section--has-accounting': _vm.hasAccounting, 
       },attrs:{"title":_vm.$t('accounting_title')}},[_c('span',[_vm._v(_vm._s(_vm.$t('accounting')))])]),_c('div',{staticClass:"px-1 text-uppercase",class:{
        'reminder-section--has-active-reminder': _vm.hasActiveReminders, 
       },attrs:{"title":_vm.$t('reminder_title')}},[_c('span',[_vm._v(_vm._s(_vm.$t('reminder')))])]),_c('div',{staticClass:"px-1 text-uppercase",class:{
        'qa-section--is-all-items-closed': _vm.isAllQuestionClosed,
        'qa-section--has-items': _vm.documentHasOpenQuestion, 
        'qa-section--has-date': _vm.hasQuestionDate && !_vm.isQuestionDateExpired, 
        'qa-section--is-date-expired': _vm.hasQuestionDate && _vm.isQuestionDateExpired
      },attrs:{"title":_vm.$t('question_title')}},[(_vm.isAllQuestionClosed||_vm.documentHasOpenQuestion)?_c('a',{staticClass:"black--text",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openQa(_vm.file, 'question' )}}},[_vm._v(" "+_vm._s(_vm.$t('question'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('question')))])]),_c('div',{staticClass:"px-1 text-uppercase",class:{
        'qa-section--is-all-items-closed': _vm.isAllTaskClosed,
        'qa-section--has-items': _vm.documentHasOpenTask, 
        'qa-section--has-date': _vm.hasTaskDate && !_vm.isTaskDateExpired, 
        'qa-section--is-date-expired': _vm.hasTaskDate && _vm.isTaskDateExpired
      },attrs:{"title":_vm.$t('task_title')}},[(_vm.isAllTaskClosed||_vm.documentHasOpenTask)?_c('a',{staticClass:"black--text",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openQa( _vm.file, 'task' )}}},[_vm._v(" "+_vm._s(_vm.$t('task'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('task')))])]),_c('div',{staticClass:"px-1 text-uppercase",class:{
        'qa-section--is-all-items-closed': _vm.isAllCircularClosed,
        'qa-section--has-items': _vm.documentHasOpenCircular, 
        'qa-section--has-date': _vm.hasCircularDate && !_vm.isCircularDateExpired, 
        'qa-section--is-date-expired': _vm.hasCircularDate && _vm.isCircularDateExpired
      },attrs:{"title":_vm.$t('circular_title')}},[(_vm.isAllCircularClosed|| _vm.documentHasOpenCircular)?_c('a',{staticClass:"black--text text-decoration-underline",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openQa(_vm.file, 'circular')}}},[_vm._v(" "+_vm._s(_vm.$t('circular'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('circular')))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }