import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { URLS } from '@/shared/backend';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('allocation');
const allocationsExportModule = namespace('allocationsExport');
const allocationsExportAllocationModule = namespace('allocationsExportAllocation');

@Component({
  components: {},
})
export default class AllocationExportDetailsView extends Vue {
  @allocationsExportModule.Action('markAsDownloadedAllocationExport')
  private actionMarkAsDownloadedAllocationExport!: any;

  @allocationsExportAllocationModule.Getter('getAllocationsExportAllocations')
  private getAllocationsExportAllocations!: any;

  @allocationsExportAllocationModule.Action('getAllocationsExportAllocationByIdExportId')
  private actionGetAllocationsExportAllocationByIdExportId!: any;

  private allocationExports: any = {
    searchParams: {},
  };

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('invoiceDate'), value: 'allocation.invoiceDate' },
      { text: this.$t('name'), value: 'name' },
    ];
    return headers;
  }

  get exportId() {
    return this.$route.params['exportId'];
  }

  get exportDate() {
    return this.$route.params['accountExportDate'];
  }

  onClickDownload(item: any) {
    this.openFile(item.allocationsExportFileId, item.allocationsExportFileName, item.allocationsExportId);
  }

  created() {
    this.actionGetAllocationsExportAllocationByIdExportId(this.exportId);
  }

  dateISOToLocalMonthYear(isoDate: any) {
    return DateUtils.formatDateMonth(isoDate);
  }

  dateISOToLocal(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale, false, true);
  }

  private openFile(fileId: string, shortFilename: string, exportId: string) {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${fileId}`;
    this.download(fileUrl, shortFilename);
    this.actionMarkAsDownloadedAllocationExport(exportId).then(() => {});
  }

  private download(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  private open(item: any) {
    this.$router.push({
      name: 'document-preview',
      params: { documentId: item.allocation.documentId, accountExportDate: this.exportDate, exportId: this.exportId },
    });
  }
}
