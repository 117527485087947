import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import DocumentListItem from '@/components/document-list-item/document-list-item.vue';
import { Logger } from 'fsts';
import { OdataItems } from '@/shared/model/OdataItems';
import { Document } from '@/shared/model/document';
import { Route } from 'vue-router';
import { Folder } from '@/shared/model/folder';
import JsObjectUtils from '@/shared/utils/jsObjectUtils';
import store from '@/shared/store';
import DocumentQaDialog from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-qa/document-qa-dialog/document-qa-dialog.vue';
import QaOverviewDialog from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-qa/qa-overview-dialog/qa-overview-dialog.vue';

import { SearchParams } from '@/shared/model/searchParams';
import { FilterBtnData } from '@/shared/model/smallPayloadModels/filterBtnData';
import updatedDiff from '@/shared/utils/deep-object-utils';
import { getDefault } from '@/shared/model/DataOptions';
import RouterUtils from '@/shared/utils/RouterUtils';
import documentStatus, { DocumentStatus, DocumentStatusValues } from '@/shared/model/documentStatus';
import actionLog, { ActionLog } from '@/shared/model/actionLog';
import DocumentPreviewMini from '@/views/documentList/document-preview-mini/document-preview-mini.vue';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import DateUtils from '@/shared/utils/DateUtils';
import { OrganisationDocumentListSetting } from '@/shared/model/organisationDocumentListSetting';

const authModule = namespace('auth');
const logger = new Logger('document-list');
const documentModule = namespace('document');
const folderModule = namespace('folder');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const userFileModule = namespace('userFile');
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const organisationDocumentPropertyModule = namespace('organisationDocumentProperty');
const organisationDocumentListSettingModule = namespace('organisationDocumentListSetting');

@Component({
  components: { DocumentListItem, DocumentQaDialog, QaOverviewDialog, DocumentPreviewMini },
})
export default class DocumentList extends Vue {
  @Getter('isListviewMode') private isListviewMode: any;

  @organisationDocumentListSettingModule.Action('getOrganisationDocumentListSettings')
  private actionGetOrganisationDocumentListSettings!: any;
  @organisationDocumentListSettingModule.Getter('getOrganisationDocumentListSettings')
  private getOrganisationDocumentListSettings!: any;

  @documentModule.Action('getDocumentsWithChildren')
  private actionGetDocumentsWithChildren!: any;

  @documentModule.Getter('getDocuments')
  private getterDocuments!: OdataItems<Document>;
  @documentModule.Mutation('setDocumentSearchData')
  private mutationSetDocumentSearchData!: any;
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: {
    searchParams: SearchParams;
    showDeleted: boolean;
    isOnlyCurrentFolderDocs: boolean;
    folderId: string;
    searchData: { searchWord: string; field: string; searchType: string };
    chipData: string;
    filterData: Array<FilterBtnData>;
  };
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;

  @documentModule.Getter('getSelectionMode')
  private getterDocumentSelectionMode!: any;

  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocs!: any;
  @documentModule.Mutation('setSelectedDocumentIds')
  private mutationSelectedDocumentIds!: any;

  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  @authModule.Getter('getAccount')
  private account!: any;
  @authModule.Action('updateLeftMenuOpenState')
  private actionUpdateLeftMenuOpenState: any;
  @authModule.Action('updateHeaderNavData')
  private actionUpdateHeaderNavData: any;
  @authModule.Getter('getHeaderNavData')
  private getHeaderNavData: any;

  @organisationDocumentExtraStatusSettingModule.Getter('getNameByValue')
  private getNameByValue!: (s: string) => {};

  @organisationDocumentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private organisationDocumentPropertys!: OdataItems<OrganisationDocumentProperty>;

  private GetDocumentPropertyName(id: string) {
    const values = this.organisationDocumentPropertys.items.filter((x: any) => x.fieldId == id);
    return values.length == 0 ? '' : values[0].name;
  }

  private paginationSync: {
    page: number;
    itemsPerPage: number;
    pageStart: number;
    pageStop: number;
    pageCount: number;
    itemsLength: number;
  } = { page: 0, itemsPerPage: 0, pageStart: 0, pageStop: 0, pageCount: 0, itemsLength: 0 };

  updatePagination(pagination: any) {
    this.paginationSync.page = pagination.page;
    this.paginationSync.itemsPerPage = pagination.itemsPerPage;
    this.paginationSync.pageStart = pagination.pageStart;
    this.paginationSync.pageStop = pagination.pageStop;
    this.paginationSync.pageCount = pagination.pageCount;
    this.paginationSync.itemsLength = pagination.itemsLength;
  }

  private docItemStatusValues: DocumentStatus[] = [
    // 'Frage',
    // '',
    // 'Aufgabe',
    // '',
    // 'Rundschreiben',
    // '',
    documentStatus.parse({ statusValue: 'GOBD_examination', name: 'GoBD Prüfung' }),
    documentStatus.parse({ statusValue: 'note', name: 'Notiz' }),
    documentStatus.parse({ statusValue: 'contest', name: 'Kontiert' }),
    // // keep values to see them at once on load, otherwise can be empty values for a second while load the values (especially on the login)
    // 'Genehmigt',
    // 'Endkontrolle',
    // 'Zahlung offen',
    // 'Prüfen',
    // 'DMS gebucht',
    // 'per Lastschrift',
    // 'Extra4',
    // 'Extra5',
    // 'Extra6',
  ];

  // (ED-1219) client request to hide `left-menu` when click on `Total number...` header or empty area on the right
  private hideLeftDrawer() {
    this.actionUpdateLeftMenuOpenState(false);
  }

  //#region Handle routing (when load deleted vs usual items)
  beforeRouteEnter(to: Route, from: Route, next: any) {
    logger.log('beforeRouteEnter');

    // `this` is `UNDEFINED` here since component has not loaded yet, so need to use `vm` in `next()`
    next((vm: any) => {
      vm.handleNavigation(to, from, vm);
    });
  }

  beforeRouteUpdate(to: Route, from: Route, next: any) {
    this.resetPageNumber(); // when navigate to different route
    logger.log('beforeRouteUpdate to :>> ', to);
    this.handleNavigation(to, from, this);
    next();
  }

  // decided that this is the better way to keep filtration when come back to `document-list` from `document-preview` than make some complex interactions with VUEX (setFilter, getPreviousRoute ) and reset its changes
  handleNavigation(to: Route, from: Route, context: any) {
    this.loadDocumentsFromLeftMenu(this.$route.query);
  }

  //#endregion
  private itemsPerPageOptionsArray = [25, 50, 100];

  // (ED-1060) change previous `documentsSearchPayload` watcher to current `documentsSearchPayload.searchParams.dataOption` to avoid duplicate (2nd request) to `getDocuments` after `searchInRealDocuments` method call (`menu-document-list`)
  @Watch('documentsSearchPayload.searchParams.dataOption', { deep: true })
  public async onOptionsPageChanged(newVal: any, oldVal: any) {
    const diff = updatedDiff(newVal, oldVal);
    if (Object.keys(diff).length !== 0) {
      if (this.documentsSearchPayload.isOnlyCurrentFolderDocs == true) {
        await this.actionGetDocumentsWithChildren({ folderId: this.documentsSearchPayload.folderId });
      } else {
        await this.actionGetDocuments();
      }
      this.scrollToTopOfPage(); // (ED-1141) (client requirement) if change page from bottom pagination, then scrollTo TOP of page
    }
  }

  @Watch('getterSelectedDocs', { immediate: true, deep: true })
  onSelectedChange(newVal: any, oldVal: any) {
    if (newVal.length === 0) {
      this.clearSelection();
    }
    if (newVal.length === this.filesCount) {
      this.selectAllItems();
    }
  }

  //#region (ED-1211) Preload previews for 2 first rows of documents in `document-list`
  @userFileModule.Action('getUserFilePreview')
  private getUserFilePreview!: any;

  private GetPreviewFileId(document: Document) {
    return document.previewFileId !== RouterUtils.emptyGuid
      ? document.previewFileId
      : document?.documentFiles?.find((x) => x.file?.kind == 'file preview image')?.fileId;
  }

  private IsPreviewHasZeroSize(document: Document) {
    const file = document?.documentFiles?.find((x) => x.file?.kind == 'file preview image')?.file;
    return file && file.fileSize === 0;
  }

  //#endregion
  DisplayedDocumentStates(value: { name: string; id: string; updatedAt?: Date }[]) {
    return value.filter(
      (x: any) =>
        x.name != DocumentStatusValues.New &&
        x.name != DocumentStatusValues.NewInFolder &&
        x.name != DocumentStatusValues.Hasgobd &&
        x.name != DocumentStatusValues.Documented &&
        x.name != DocumentStatusValues.Allocated &&
        x.name != ''
    );
  }
  get files() {
    // (ED-1211) need this intermediate `documents` variable, to delay showing documents until previews for 2 first rows are loaded
    // if use `getterDocuments` then Documents showed at once and previews loaded after 2-4 seconds
    return this.getterDocuments;
  }

  get filesTotalCount() {
    return this.getterDocuments.total;
  }

  get documentsCountHeader() {
    // (ED-970) Avoid showing wrong number of documents in the header (when empty result for COUNT `All documents` with empty `@odata.count: 13, value: []` is returned)
    if (this.filesCount) {
      return this.$i18n.t('number_of_docs_title', { 0: this.filesTotalCount });
    }
  }

  get filesCount() {
    return this.getterDocuments.items.length;
  }

  get isSelectionMode() {
    return this.getterDocumentSelectionMode;
  }

  created() {
    // (ED-370): Attempt to reuse logic to Update(document-list) from `App.ts`(header btn), `menu-document-list` and `file-upload` (dialog) using `getDocumentsWithChildren` action + logic
    // TODO: (refactor) (ED-370) maybe redo this approach with `$root.$refs` with Vuex or EventBus
    this.$root.$refs.DocumentListCmp = this;

    this.loadDocumentExtraStatusSettingsData(); // (ED-1072) need to query `DocumentExtraStatusSettingsData` here to format results and replace placeholder values from `docItemStatusValues` array
    this.actionGetOrganisationDocumentListSettings();
  }

  private scrollToTopOfPage() {
    const headerHeight = 100;
    if (window.scrollY > headerHeight) {
      window.scrollTo(0, 0);
    }
  }

  mounted() {
    this.setAppHeader();
  }
  setAppHeader() {
    if (this.getHeaderNavData.translateId === 'addresses_documents') {
      this.actionUpdateHeaderNavData({ translateId: 'addresses', to: '/addresses' });
    }
    if (this.isTrashRoute()) {
      this.actionUpdateHeaderNavData({ translateId: 'trash', to: '/trash' });
    }
  }

  //#region Paging logic
  reloadData() {
    const route = this.$route;
    this.handleNavigation(route, route, this); // `page` and `itemsPerPage` values will set in Vuex State by `reloadDataOnPageChange` and `reloadDataItemsPerPageChange` methods
  }

  get currentPage() {
    return this.getterDocuments.searchParams.dataOption.page;
  }

  private resetPageNumber() {
    this.getterDocuments.searchParams.dataOption.page = 1;
  }
  //#endregion

  private async loadDocumentExtraStatusSettingsData() {
    // put the `DocumentExtraStatusSettings` in State from `document-list` to use in every `document-list-item` from getter to avoid extra requests
    await this.actionGetOrganisationDocumentExtraStatusSettings();
    this.formatResult(this.getOrganisationDocumentExtraStatusSettings.items);
  }

  /**
   * Format the values (statuses) which will be passed to `document-list-item` by replacing the last 9 statuses from `values` array variable with `Statuses` from backend (statuses can be renamed by user)
   * @param {Array<any>} result from GetOrganisationDocumentExtraStatusSettings Vuex action
   */
  private formatResult(result: Array<any>) {
    const extraStatusNames: DocumentStatus[] = result.map((x: any) =>
      documentStatus.parse({ name: x.name, statusValue: x.statusValue, allowExport: x.allowExport })
    );
    this.docItemStatusValues = [...this.docItemStatusValues.slice(0, 3), ...extraStatusNames];
  }

  //#region Logic to get array of Parent folderId with all Children folderId
  @folderModule.Getter('getMenuFolders')
  private getMenuFolders!: OdataItems<Folder>;

  /**
   * Check if key exist in object
   * @param {string} string object key (key always `string`, but value can be anything)
   * @param {object} object
   * @returns {boolean} Is key exist in object
   */
  isKeyExistInObject(key: string, object: {}) {
    return key in object;
  }

  /**
   * Check if value exist in object
   * @param {any} any object value
   * @param {object} object
   * @returns {boolean} Is value exist in object
   */
  isValueExistInObject(value: any, object: {}) {
    return Object.values(object).includes(value);
  }

  /**
   * Check if current route is `Trash` folder using global `$route`
   * @returns {boolean} Is value exist in object
   */
  isTrashRoute() {
    return this.$route.path === '/trash';
  }

  loadDocumentsFromLeftMenu(routeQuery: any) {
    // need to check empty object to correctly `Update documents` for `all documents` and `Trash` categories in left-menu
    const onlyCurrentFolderDocs = JsObjectUtils.isEmptyObject(routeQuery)
      ? true
      : JSON.parse(routeQuery['onlyCurrentFolderDocs']); // convert String 'true/false` query param from Route to actual Boolean
    this.actionGetDocuments().then(() => {
      if (this.$route.params.documentId) this.$vuetify.goTo(`#document-list-item-id-${this.$route.params.documentId}`);
    });
  }

  //#endregion

  private selectedDocumentIds: Set<string> = new Set();
  private clickDocument(payload: { id: string; isSelected: boolean }) {
    payload.isSelected ? this.selectedDocumentIds.add(payload.id) : this.selectedDocumentIds.delete(payload.id);
    const selectedDocumentIdsArray = [...this.selectedDocumentIds]; // convert set (no possible duplicates) to array
    this.mutationSelectedDocumentIds(selectedDocumentIdsArray);
  }

  private clickDocumentOpen(item: any) {
    this.$router.push({
      name: 'document-preview',
      params: { documentId: item.id },
    });
  }

  private clearSelection() {
    this.selectedDocumentIds.clear();
    this.$emit('update:selected', false);
  }

  private selectAllItems() {
    this.$emit('update:selected', true);
    this.selectedDocumentIds = new Set([...this.getterSelectedDocs]); // need to update Set, otherwise Empty (size = 0) and if click on any `document-list-Item` everything will be cleared since EMPTY ARRAY will be sent to VUEX in `clickDocument`
  }

  getMessageById(actionLogId: string, file: Document): ActionLog {
    return file.actionLogs?.find((x: ActionLog) => x.actionLogId == actionLogId) ?? actionLog.parse({});
  }

  //#region (ED-944) Document QA Dialog logic
  private showDocumentQaDialog(id: string, file: Document) {
    // const message = this.getMessageById(id, file);
    // if (message.threadId != '00000000-0000-0000-0000-000000000000') id = message.threadId;
    // this.dialogDocumentQa.startMessageId = id;
    // this.dialogDocumentQa.model = file;
    // this.dialogDocumentQa.show = true;
  }

  dialogDocumentQa = {
    show: false,
    model: {},
    startMessageId: '',
    OnClose: () => {
      this.dialogDocumentQa.show = false;
    },
    showQaOverviewDialog: () => {
      this.showQaOverviewDialog();
    },
  };
  //#endregion

  //#region QA Overview Dialog logic
  private showQaOverviewDialog() {
    this.dialogDocumentQa.show = false;
    this.dialogQaOverview.show = true;
  }

  dialogQaOverview = {
    show: false,
    model: {},

    openQaFromOverview: (qa: ActionLog) => {
      this.showDocumentQaDialogFromOverview(qa);
    },
    OnClose: () => {
      this.dialogQaOverview.show = false;
    },
  };

  private showDocumentQaDialogFromOverview(qa: ActionLog) {
    this.dialogDocumentQa.show = true;
    this.dialogDocumentQa.startMessageId = qa.id;
    this.dialogDocumentQa.model = { id: qa.documentId, organisationId: qa.organisationId };
  }

  //#endregion

  //#region  Document Preview Mini Dialog logic
  private showDocumentPreviewMiniDialog(id: string) {
    this.dialogDocumentPreviewMini.model = id;
    this.dialogDocumentPreviewMini.show = true;
  }

  dialogDocumentPreviewMini = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentPreviewMini.show = false;
    },
  };
  //#endregion

  private DateWithLocale(text: string) {
    return DateUtils.isoDateToScreenDateWithLocale(text, this.$i18n.locale, true);
  }

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      tag: string;
    }[] = [
      { text: this.$t('listview.ocr'), value: 'ocrdetectionDate', tag: 'ocr' },
      { text: this.$t('listview.document_number'), value: 'documentNumber', tag: 'document_number' },
      { text: this.$t('listview.name'), value: 'name', tag: 'name' },
      { text: this.$t('listview.created_at'), value: 'createdAt', tag: 'created_at' },
      { text: this.$t('listview.pages'), value: 'pageCount', tag: 'pages' },
      { text: this.$t('listview.extras'), value: 'documentStates', sortable: false, tag: 'extras' },
      { text: this.$t('listview.informations'), value: 'informations', sortable: false, tag: 'informations' },
      { text: this.$t('listview.adresse_kennzeichen'), value: 'adresseKennzeichen', tag: 'adresse_kennzeichen' },
      { text: this.$t('listview.adresse_kundennummer'), value: 'adresseKundennummer', tag: 'adresse_kundennummer' },
      { text: this.$t('listview.adresse_seriennummer'), value: 'adresseSeriennummer', tag: 'adresse_seriennummer' },
      { text: this.$t('listview.amount'), value: 'documentGobdDatum.amount', tag: 'amount' },
      { text: this.$t('listview.invoice_date'), value: 'documentGobdDatum_invoiceDate', tag: 'invoice_date' },
      { text: this.$t('listview.invoice_number'), value: 'documentGobdDatum.invoiceNumber', tag: 'invoice_number' },
      { text: this.$t('listview.serial_number'), value: 'documentGobdDatum.serialNumber', tag: 'serial_number' },

      { text: this.$t('listview.account'), value: 'account', tag: 'account' },
      { text: this.$t('listview.contra_account'), value: 'contra_account', tag: 'contra_account' },
      { text: this.$t('listview.tax_amount'), value: 'tax_amount', tag: 'tax_amount' },
      { text: this.$t('listview.days_clean'), value: 'days_clean', tag: 'days_clean' },
      { text: this.$t('listview.days_discount'), value: 'days_discount', tag: 'days_discount' },
      { text: this.$t('listview.discount_rate'), value: 'discount_rate', tag: 'discount_rate' },

      {
        text: this.$t('listview.supplier_account'),
        value: 'documentGobdDatum.supplierAccount',
        tag: 'supplier_account',
      },
    ];

    const array = this.getOrganisationDocumentListSettings.items;

    array.sort(function (a: OrganisationDocumentListSetting, b: OrganisationDocumentListSetting) {
      const keyA = new Number(a.sortOrder),
        keyB = new Number(b.sortOrder);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    const result: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [];

    array.forEach((item: OrganisationDocumentListSetting) => {
      const aa = headers.find((key) => key.tag == item.name);
      //console.error(aa?.value, aa?.tag, item.sortOrder, item.name)
      if (item.enabled)
        result.push({
          text: this.$t('listview.' + aa?.tag + ''),
          value: aa?.value ?? '',
          sortable: aa?.sortable,
        });
    });

    return result;
  }
}

// (ED-552) if there active search from `document-list` DO NOT get changes from backend when user goes from `searched documents` to `document-preview` and then back to `document-list`
function isDocumentsHasActiveSearch() {
  return store.getters['document/getHasActiveSearch'];
}

// (ED-1060) method to prevent pagination reset (to page 1) when return from `document-preview` to `document-list` last folder (last folder means the same folder from which `document-preview` was opened)
function isReturnFromDocumentPreviewToSameFolder(from: Route, to: Route) {
  const isFromDocumentPreview = from.name === 'document-preview';
  const isReturnToTheSamePreviousRoute =
    from.params?.urlBeforeDocPreview && from.params?.urlBeforeDocPreview === to.fullPath;

  return isFromDocumentPreview && isReturnToTheSamePreviousRoute;
}
