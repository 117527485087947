import { render, staticRenderFns } from "./allocation-details.html?vue&type=template&id=8d6737f6&scoped=true&external"
import script from "./allocation-details.ts?vue&type=script&lang=ts&external"
export * from "./allocation-details.ts?vue&type=script&lang=ts&external"
import style0 from "./allocation-details.scss?vue&type=style&index=0&id=8d6737f6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d6737f6",
  null
  
)

/* custom blocks */
import block0 from "./allocation-details.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fexports%2Fallocation%2Fallocation-details%2Fallocation-details.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
