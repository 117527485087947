import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css'; // import this to use default icons for buttons
import { namespace } from 'vuex-class';
import FileUtils from '@/shared/utils/fileUtils';
import { UserFile } from '@/shared/model/userFile';
import { Logger } from 'fsts';
import PdfViewer from 'vue-pdf-app';
import { URLS } from '@/shared/backend';
import GeneralUtils from '@/shared/utils/generalUtils';
import i18n from '@/i18n';
import { OdataItems } from '@/shared/model/OdataItems';
import { Document } from '@/shared/model/document';
import { Route } from 'vue-router';
import comment, { Comment } from '@/shared/model/comment';
import DateUtils from '@/shared/utils/DateUtils';
import documentEditSession, { DocumentEditSession } from '@/shared/model/documentEditSession';
import { AssertPredicate } from 'assert';

const logger = new Logger('document-preview');

const userFileModule = namespace('userFile');
const documentModule = namespace('document');
const authModule = namespace('auth');
const commentModule = namespace('comment');
const actionLogModule = namespace('actionLog');
const documentEditSessionModule = namespace('documentEditSession');

@Component({ name: 'document-preview', components: { VuePdfApp } })
export default class DocumentPreviewMini extends Vue {
  @userFileModule.Action('downloadUserFile')
  private actionDownloadFile: any;
  @userFileModule.Action('getUserFile')
  private actionGetFile: any;
  @userFileModule.Getter('getUserFile')
  private getFile!: UserFile;

  @documentModule.Getter('getDocuments')
  private getterGetDocuments!: OdataItems<Document>;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;
  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: any;

  @authModule.Action('updateHeaderNavData')
  private actionUpdateHeaderNavData: any;
  @authModule.Getter('getHeaderNavData')
  private getHeaderNavData: any;

  @documentEditSessionModule.Action('updateDocumentEditSession')
  private updateDocumentEditSession!: any;

  @documentEditSessionModule.Action('getDocumentEditSession')
  private getDocumentEditSession!: any;
  @documentEditSessionModule.Action('deleteDocumentEditSession')
  private deleteDocumentEditSession!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: '' })
  private model!: string;
  @Watch('model')
  onModelUpdate(newV: string, oldV: string) {
    this.getUserFile();
  }

  get documentId() {
    return this.model;
  }

  get fileId() {
    const documentFiles = this.getterGetDocument?.documentFiles?.filter(
      (x) => x.file?.kind == 'document file' || x.file?.kind == 'document metadata file'
    )!;
    documentFiles.sort((a, b) => {
      const dateA = new Date(a.file.createdAt).getTime();
      const dateB = new Date(b.file.createdAt).getTime();
      return dateA - dateB;
    });

    return documentFiles[this.currentFile]?.fileId;
  }

  get isDevEnvironment() {
    return GeneralUtils.isDevEnvironment();
  }

  get fileExtension() {
    return this.isFileInfoLoaded ? FileUtils.getFileExtension(this.getFile.documentName) : '';
  }

  get fileName() {
    return this.isFileInfoLoaded ? this.getFile.documentName : '';
  }

  get isFileInfoLoaded() {
    return this.getFile && this.getFile.documentName.length > 0;
  }

  get showPdfPreview() {
    return this.isFileInfoLoaded && FileUtils.isPdf(this.getFile.documentName);
  }

  get getMsDocPreviewUrl() {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${this.fileId}`;
    return `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`;
  }

  get showMsOfficePreview() {
    return this.isFileInfoLoaded && FileUtils.isMsDocXlsOrPpt(this.getFile.documentName);
  }

  get showImagePreview() {
    return this.isFileInfoLoaded && FileUtils.isPicture(this.getFile.documentName);
  }

  get noPreview() {
    return !this.showImagePreview && !this.showPdfPreview && !this.showMsOfficePreview;
  }

  get fileLink() {
    return this.fileBlobUrl;
  }

  get pageHeightMinusHeader() {
    return document.querySelector('.v-main')?.clientHeight + 'px';
  }

  private fileBlobUrl: string = '';

  private async getUserFile() {
    const promiseAll = [];
    if (!this.documentId) return;
    await this.actionGetDocument(this.documentId);
    promiseAll.push(
      this.actionGetFile(this.fileId).catch((err: any) => {
        logger.error(err);
      }),
      this.actionDownloadFile(this.fileId)
        .then((result: Blob) => {
          this.fileBlobUrl = URL.createObjectURL(result);
        })
        .catch((err: any) => {
          logger.error(err);
        })
    );

    Promise.all(promiseAll);
  }

  private currentFile = 0;

  private getPrevFile() {
    const documentFiles = this.getterGetDocument?.documentFiles?.filter(
      (x) => x.file?.kind == 'document file' || x.file?.kind == 'document metadata file'
    )!;
    //let previous = this.currentFile == 0 ? documentFiles.length - 1 : this.currentFile - 1;
    const previous = this.currentFile == 0 ? 0 : this.currentFile - 1;
    if (this.currentFile != previous) {
      this.currentFile = previous;
      this.getUserFile();
    }
  }

  private getNextFile() {
    const documentFiles = this.getterGetDocument?.documentFiles?.filter(
      (x) => x.file?.kind == 'document file' || x.file?.kind == 'document metadata file'
    )!;
    //let next = this.currentFile == documentFiles.length - 1 ? 0 : this.currentFile + 1;
    const next = this.currentFile == documentFiles.length - 1 ? documentFiles.length - 1 : this.currentFile + 1;
    if (this.currentFile != next) {
      this.currentFile = next;
      this.getUserFile();
    }
  }

  get hasMultipleFiles() {
    return this.getterGetDocument?.documentFiles!.length > 1;
  }

  get classFooterOrNot() {
    return 'document-preview__pdf-wrapper';
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    to.params.urlBeforeDocPreview = from.fullPath; // (ED-1060) need `urlBeforeDocPreview` to know if we return to the same folder from `document-preview` route (if YES keep the paging info, if NO reset paging to page 1)
    next((vm: any) => {});
  }

  destroyed() {
    // revoke Blob URL to the browser cleared the memory from the downloaded file, when navigate away from the `document-preview` component
    URL.revokeObjectURL(this.fileBlobUrl);
  }

  clickClose() {
    URL.revokeObjectURL(this.fileBlobUrl);
    this.$emit('click:close');
  }

  // image preview
  // https://hackernoon.com/a-basic-guide-to-event-handling-in-vue

  mouseDownX() {}
  mouseUpX() {}

  /* private mousePressed = false;
  mouseDownX(e: MouseEvent) {
    this.mousePressed = true;
    document.getElementById('imagePreviewX')!.style!.opacity = '1';
    document.getElementById('imagePreview')!.style!.opacity = '0';

    document.getElementById('imagePreviewX')!.style!.top = -e.clientY * 2 + 'px';
    document.getElementById('imagePreviewX')!.style!.left = -e.clientX * 2 + 'px';
  }

  mouseUpX() {
    this.mousePressed = false;
    document.getElementById('imagePreviewX')!.style!.opacity = '0';
    document.getElementById('imagePreview')!.style!.opacity = '1';

    document.getElementById('imagePreviewX')!.style!.top = '0px';
    document.getElementById('imagePreviewX')!.style!.left = '0px';
  }

  mousemoveX(e: MouseEvent) {
    if (!this.mousePressed) return;
    let sqr = document.getElementById('imagePreviewX');

    let valX = parseInt(getComputedStyle(sqr!).top, 10);
    if (valX + e.movementY * 2 < e.clientY) sqr!.style.top = valX + e.movementY * 2 + 'px';

    let valY = parseInt(getComputedStyle(sqr!).left, 10);
    if (valY + e.movementX * 2 < e.clientX) sqr!.style.left = valY + e.movementX * 2 + 'px';
  } */

  // -------------------------------------- templating

  //colorize log output
  //console.log('📕: error message');
  //console.log('📙: warning message');
  //console.log('📗: ok status message');
  //console.log('📘: action message');
  //console.log('📓: canceled status message');
  //console.log('📔: Or anything you like and want to recognize immediately by color');

  async openHandler(pdfApp: any) {
    //https://github.com/sandanat/vue-pdf-app/blob/06107045276b4e2f61fb1b05aa98f87eb42cac7a/src/pdfjs-dist/lib/test/unit/pdf_find_controller_spec.js
    //https://github.com/ocrmypdf/OCRmyPDF
    const words = this.documentsSearchPayload.chipDataArray.map((element: any) => element.searchWord);
    const searchWords = words.join(' ');

    if (searchWords.length > 0) {
      //console.log('📗', searchWords);
      pdfApp.pdfViewer.findController.executeCommand('find', {
        query: searchWords,
        caseSensitive: false,
        entireWord: false,
        phraseSearch: false,
        findPrevious: undefined,
        highlightAll: true,
      });
    }

    const info = await pdfApp.pdfDocument.getMetadata().catch(console.error.bind(console));
    if (!info) return;
    const props = Object.keys(info.info);
    props.forEach((prop) => {
      const obj = {
        name: prop,
        value: info.info[prop],
      };
    });

    pdfApp.pdfDocument.getPage(1).then((page: any) => {
      if (page.rotate == 270) {
        this.originalWidth = page.view[3];
        this.originalHeight = page.view[2];
      } else {
        this.originalWidth = page.view[2];
        this.originalHeight = page.view[3];
      }
      console.log('original size: ' + this.originalWidth + ',' + this.originalHeight);
      this.image150DPIWidth = Math.floor(this.originalWidth * 2.084);
      this.image150DPIHeight = Math.floor(this.originalHeight * 2.084);
    });
  }

  private originalWidth: number = 0;
  private image150DPIWidth: number = 0;
  private originalHeight: number = 0;
  private image150DPIHeight: number = 0;
  private scale: number = 1;
  private scaleCanvasTo150DPI: number = 1;
  private pdfApp: any;

  afterCreatedHandler(pdfApp: any) {
    console.log('afterCreatedHandler');
    this.pdfApp = pdfApp;
  }

  pagesRendered(pdfApp: any) {
    console.log('pagesRendered');
  }
}
