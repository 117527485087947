import { render, staticRenderFns } from "./document-preview-mini.html?vue&type=template&id=6d314608&scoped=true&external"
import script from "./document-preview-mini.ts?vue&type=script&lang=ts&external"
export * from "./document-preview-mini.ts?vue&type=script&lang=ts&external"
import style0 from "./document-preview-mini.scss?vue&type=style&index=0&id=6d314608&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d314608",
  null
  
)

/* custom blocks */
import block0 from "./document-preview-mini.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2FdocumentList%2Fdocument-preview-mini%2Fdocument-preview-mini.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VIcon,VLayout})
