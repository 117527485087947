import { render, staticRenderFns } from "./document-list.html?vue&type=template&id=5feaee5e&scoped=true&external"
import script from "./document-list.ts?vue&type=script&lang=ts&external"
export * from "./document-list.ts?vue&type=script&lang=ts&external"
import style0 from "./document-list.scss?vue&type=style&index=0&id=5feaee5e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5feaee5e",
  null
  
)

/* custom blocks */
import block0 from "./document-list.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2FdocumentList%2Fdocument-list.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VChip,VCol,VContainer,VDataFooter,VDataIterator,VDataTable,VIcon,VRow,VTooltip})
