import { Component, Vue, Watch } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css'; // import this to use default icons for buttons
import { namespace } from 'vuex-class';
import FileUtils from '@/shared/utils/fileUtils';
import { UserFile } from '@/shared/model/userFile';
import { Logger } from 'fsts';
import templateMarkup, { TemplateMarkup } from '@/shared/model/templateMarkup';
import { URLS } from '@/shared/backend';
import GeneralUtils from '@/shared/utils/generalUtils';
import i18n from '@/i18n';
import { OdataItems } from '@/shared/model/OdataItems';
import { Document } from '@/shared/model/document';
import { Route } from 'vue-router';
import comment, { Comment } from '@/shared/model/comment';
import DateUtils from '@/shared/utils/DateUtils';
import documentEditSession, { DocumentEditSession } from '@/shared/model/documentEditSession';
import user, { User } from '@/shared/model/user';
import store from '@/shared/store';

const logger = new Logger('document-preview');

const userFileModule = namespace('userFile');
const documentModule = namespace('document');
const authModule = namespace('auth');
const userModule = namespace('user');
const templateMarkupeModule = namespace('templateMarkup');
const commentModule = namespace('comment');
const actionLogModule = namespace('actionLog');
const documentEditSessionModule = namespace('documentEditSession');

@Component({ name: 'document-preview', components: { VuePdfApp } })
export default class DocumentPreview extends Vue {
  @actionLogModule.Action('getActionLogsQa')
  private getActionLogsQa!: any;
  @userFileModule.Action('downloadUserFile')
  private actionDownloadFile!: (x: string) => Promise<Blob>;
  @userFileModule.Action('getUserFile')
  private actionGetFile: any;
  @userFileModule.Getter('getUserFile')
  private getFile!: UserFile;

  @documentModule.Getter('getDocuments')
  private getterGetDocuments!: OdataItems<Document>;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;
  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: any;

  @authModule.Mutation('setIsLeftDrawerShown')
  private mutationSetIsLeftDrawerShown: any;
  @authModule.Action('updateHeaderNavData')
  private actionUpdateHeaderNavData: any;
  @authModule.Getter('getHeaderNavData')
  private getHeaderNavData: any;

  @templateMarkupeModule.Getter('getIsTemplateMode')
  private getterIsTemplateMode!: any;

  @templateMarkupeModule.Getter('getTemplateMarkups')
  private getterGetTemplateMarkups!: any;
  @templateMarkupeModule.Action('getTemplateMarkups')
  private actionGetTemplateMarkups!: any;
  @templateMarkupeModule.Action('addTemplateMarkup')
  private actionAddTemplateMarkup!: any;
  @templateMarkupeModule.Action('clearTemplateMarkup')
  private actionClearTemplateMarkup!: any;
  @templateMarkupeModule.Action('deleteTemplateMarkupByDocumentId')
  private actionDeleteTemplateMarkupByDocumentId!: any;

  @templateMarkupeModule.Getter('getTemplateMarkupsScale')
  private getterGetTemplateMarkupsScale!: any;
  @templateMarkupeModule.Action('setTemplateMarkupsScale')
  private actionSetTemplateMarkupsScale!: any;

  @templateMarkupeModule.Getter('getLastRecognizedTextInBox')
  private getterGetLastRecognizedTextInBox!: any;
  @templateMarkupeModule.Action('getLastRecognizedTextInBox')
  private actionGetLastRecognizedTextInBox!: any;

  @userModule.Action('getUser')
  private getUser!: any;
  @authModule.Getter('getAccount')
  private account!: any;

  private user: User = user.parse({});

  //#region comments for Vorlagen
  @commentModule.Action('getComments')
  private actionGetComments!: any;
  @commentModule.Getter('getComments')
  private getComments!: any;

  @documentEditSessionModule.Action('updateDocumentEditSession')
  private updateDocumentEditSession!: any;

  @documentEditSessionModule.Action('getDocumentEditSession')
  private getDocumentEditSession!: any;
  @documentEditSessionModule.Action('deleteDocumentEditSession')
  private deleteDocumentEditSession!: any;

  get comments() {
    return this.getComments.items;
  }

  private createFileDate(item: Comment) {
    return item.createdAt ? DateUtils.dotDateTimeFromIsoDateTime(item.createdAt) : 'NO DATE';
  }

  private notLastMessage(index: number) {
    return index + 1 < this.comments.length;
  }

  //#endregion

  get isTemplateMode() {
    return this.getterIsTemplateMode === true;
  }

  get getTemplateMarkups() {
    return this.getterGetTemplateMarkups;
  }

  get documentId() {
    return this.$route.params['documentId'];
  }
  get fileId() {
    const documentFiles = this.getterGetDocument?.documentFiles?.filter(
      (x) =>
        (x.file?.kind == 'document file' || x.file?.kind == 'document metadata file') &&
        (x.mergedToFileId == x.fileId || x.mergedToFileId == '00000000-0000-0000-0000-000000000000')
    )!;
    documentFiles.sort((a, b) => {
      const dateA = new Date(a.file.createdAt).getTime();
      const dateB = new Date(b.file.createdAt).getTime();
      return dateA - dateB;
    });

    return documentFiles[this.currentFile]?.fileId;
  }

  get isDevEnvironment() {
    return GeneralUtils.isDevEnvironment();
  }

  get fileExtension() {
    return this.isFileInfoLoaded ? FileUtils.getFileExtension(this.getFile.documentName) : '';
  }

  get isFileInfoLoaded() {
    return this.getFile && this.getFile.documentName.length > 0;
  }

  get showPdfPreview() {
    return this.isFileInfoLoaded && FileUtils.isPdf(this.getFile.documentName);
  }

  get getMsDocPreviewUrl() {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${this.fileId}`;
    return `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`;
  }

  get getVideoPreviewUrl() {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${this.fileId}`;
    return `${fileUrl}`;
  }

  get showMsOfficePreview() {
    return this.isFileInfoLoaded && FileUtils.isMsDocXlsOrPpt(this.getFile.documentName);
  }

  get showImagePreview() {
    return this.isFileInfoLoaded && FileUtils.isPicture(this.getFile.documentName);
  }

  get showVidePreview() {
    return this.isFileInfoLoaded && FileUtils.isVideo(this.getFile.documentName);
  }

  get noPreview() {
    return !this.showImagePreview && !this.showPdfPreview && !this.showMsOfficePreview;
  }

  get fileLink() {
    if (this.fileBlobUrl) return this.fileBlobUrl;
  }

  get pageHeightMinusHeader() {
    return document.querySelector('.v-main')?.clientHeight + 'px';
  }

  get Scale() {
    return this.getterGetTemplateMarkupsScale;
  }

  private fileBlobUrl: string = '';

  private async getUserFile() {
    const promiseAll = [];

    await this.actionGetDocument(this.documentId);
    logger.debug(`fileId: ${this.fileId}`);

    const altFiles: any = this.getterGetDocument?.alternativeFiles?.filter((x) => x.fileId == this.fileId);
    let altFileId = null;
    if (altFiles?.length! > 0) {
      altFileId = altFiles[0].alternativeFileId;
    }

    if (this.fileId) {
      await this.actionGetFile(altFileId ?? this.fileId);
      const result = await this.actionDownloadFile(altFileId ?? this.fileId);
      if (result) this.fileBlobUrl = URL.createObjectURL(result);
    }
  }

  private currentFile = 0;

  private getPrevFile() {
    const documentFiles = this.getterGetDocument?.documentFiles?.filter(
      (x) => x.file?.kind == 'document file' || x.file?.kind == 'document metadata file'
    )!;
    //let previous = this.currentFile == 0 ? documentFiles.length - 1 : this.currentFile - 1;
    const previous = this.currentFile == 0 ? 0 : this.currentFile - 1;
    if (this.currentFile != previous) {
      this.currentFile = previous;
      this.getUserFile();
    }
  }

  private getNextFile() {
    const documentFiles = this.getterGetDocument?.documentFiles?.filter(
      (x) => x.file?.kind == 'document file' || x.file?.kind == 'document metadata file'
    )!;
    //let next = this.currentFile == documentFiles.length - 1 ? 0 : this.currentFile + 1;
    const next = this.currentFile == documentFiles.length - 1 ? documentFiles.length - 1 : this.currentFile + 1;
    if (this.currentFile != next) {
      this.currentFile = next;
      this.getUserFile();
    }
  }

  get hasMultipleFiles() {
    let files = this.getterGetDocument?.documentFiles?.filter(
      (x) => x.fileId == x.mergedToFileId || x.mergedToFileId == '00000000-0000-0000-0000-000000000000'
    );
    return files!.length > 1;
  }

  get classFooterOrNot() {
    return {
      'document-preview__pdf_with_footer-wrapper': this.hasMultipleFiles,
      'document-preview__pdf-wrapper': !this.hasMultipleFiles,
    };
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    to.params.urlBeforeDocPreview = from.fullPath; // (ED-1060) need `urlBeforeDocPreview` to know if we return to the same folder from `document-preview` route (if YES keep the paging info, if NO reset paging to page 1)
    next((vm: any) => {});
  }

  beforeRouteUpdate(to: Route, from: Route, next: any) {
    this.getDocumentEditSession(to.params['documentId']).then((response: DocumentEditSession) => {
      if (response.isCurrentUser || !response) {
        this.deleteDocumentEditSession(this.documentId);
        next();
      } else {
        const user = response.fullUserName;
        this.$confirm
          .open(
            `${this.$t('dialogs.already_opened.title')}`,
            `${this.$t('dialogs.already_opened.message', {
              0: response.documentId,
              1: response.documentId,
              2: user,
            })}`,
            {
              cancelText: this.$t('dialogs.already_opened.cancel'),
              okText: this.$t('dialogs.already_opened.open'),
            }
          )
          .then(async (response: any) => {
            if (response) {
              this.deleteDocumentEditSession(this.documentId);
              next();
            } else {
              next(false);
            }
          });
      }
    });
  }

  isFirstBeforeRouteLeaveRunning = false;
  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (!this.isFirstBeforeRouteLeaveRunning) {
      this.deleteDocumentEditSession(this.documentId);
      this.isFirstBeforeRouteLeaveRunning = true;
    }
    next();
  }

  async mounted() {
    const editSession: DocumentEditSession = documentEditSession.parse({ documentId: this.documentId });
    this.updateDocumentEditSession(editSession);
    await this.getActionLogsQa({ searchData: { documentId: this.documentId } });
  }

  async created() {
    this.addCanvasEventListener();

    /// check of correct value of ENV variable (TODO: (ED-444) remove after test)
    console.log('process.env.NODE_ENV :>> ', process.env.NODE_ENV);
    logger.log('process.env.VUE_APP_BASE_PATH :>> ', process.env.VUE_APP_BASE_PATH);
    logger.log('process.env.VUE_KEEPTIME_BACKEND_API_URL :>> ', process.env.VUE_KEEPTIME_BACKEND_API_URL); // used in src\shared\backend\index.ts (line:9 ~ `instance` variable)

    this.mutationSetIsLeftDrawerShown(false); // hide `left-menu` when open the document
    this.setAppHeader();
    this.getUserFile();
    this.user = await this.getUser(this.account.profile.sub);
  }

  setAppHeader() {
    // (ED-1203) set `Adressen-Dokumente` header text if open document after click on Addresses table
    if (this.getHeaderNavData.translateId === 'addresses') {
      this.actionUpdateHeaderNavData({
        translateId: 'addresses_documents',
        to: '',
      });
    }
  }

  destroyed() {
    // revoke Blob URL to the browser cleared the memory from the downloaded file, when navigate away from the `document-preview` component
    if (this.fileBlobUrl) URL.revokeObjectURL(this.fileBlobUrl);
  }

  // image preview
  // https://hackernoon.com/a-basic-guide-to-event-handling-in-vue

  private mousePressed = false;
  mouseDownX(e: MouseEvent) {
    this.mousePressed = true;
    document.getElementById('imagePreviewX')!.style!.opacity = '1';
    document.getElementById('imagePreview')!.style!.opacity = '0';

    document.getElementById('imagePreviewX')!.style!.top = -e.clientY * 2 + 'px';
    document.getElementById('imagePreviewX')!.style!.left = -e.clientX * 2 + 'px';
  }

  mouseUpX() {
    this.mousePressed = false;
    document.getElementById('imagePreviewX')!.style!.opacity = '0';
    document.getElementById('imagePreview')!.style!.opacity = '1';

    document.getElementById('imagePreviewX')!.style!.top = '0px';
    document.getElementById('imagePreviewX')!.style!.left = '0px';
  }

  mousemoveX(e: MouseEvent) {
    if (!this.mousePressed) return;
    const sqr = document.getElementById('imagePreviewX');

    const valX = parseInt(getComputedStyle(sqr!).top, 10);
    if (valX + e.movementY * 2 < e.clientY) sqr!.style.top = valX + e.movementY * 2 + 'px';

    const valY = parseInt(getComputedStyle(sqr!).left, 10);
    if (valY + e.movementX * 2 < e.clientX) sqr!.style.left = valY + e.movementX * 2 + 'px';
  }

  // -------------------------------------- templating

  // edit markup on canvas
  private isMarkup: boolean = false;
  private editFieldName: string = '';
  private MarkerText: string = '';

  get items() {
    const items = [
      { id: '', value: '' },
      { id: 'iban', value: this.$t('document_preview_marker_fields.iban') }, //informationen - documents
      { id: 'homepage', value: this.$t('document_preview_marker_fields.homepage') }, //informationen - documents
      { id: 'tax_number', value: this.$t('document_preview_marker_fields.tax_number') }, //informationen - documents
      { id: 'amount', value: this.$t('document_preview_marker_fields.amount') }, //gobd - DocumentGobdDatums
      { id: 'document_date', value: this.$t('document_preview_marker_fields.document_date') }, //gobd - DocumentGobdDatums
      { id: 'voucher_number', value: this.$t('document_preview_marker_fields.voucher_number') },
      { id: 'indicator', value: this.$t('document_preview_marker_fields.indicator') },
      { id: 'serial_number', value: this.$t('document_preview_marker_fields.serial_number') },
      { id: 'customer_number', value: this.$t('document_preview_marker_fields.customer_number') },
    ];
    return items;
  }

  get filteredItems() {
    if (this.getterGetTemplateMarkups.items.length != 0) {
      return this.items.filter(
        (h) =>
          this.getterGetTemplateMarkups.items.find((o: any) => o.fieldName === h.id) === undefined ||
          h.id == '' ||
          h.id == this.editFieldName
      );
    }
    return this.items;
  }

  FieldNameFromid(id: any) {
    let value: any = '';
    this.items.forEach((element) => {
      if (element.id == id) value = element.value;
    });
    return value;
  }

  get MarkupLabel() {
    if (this.isMarkup) {
      return 'Marker';
    } else {
      return 'Field';
    }
  }

  addCanvasEventListener() {
    window.addEventListener('resize', this.resizeCanvas, false);
    window.addEventListener('click', this.hideMenu, false);
    window.addEventListener('scroll', this.hideMenuElement, false);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.hideMenuElement);
    window.removeEventListener('click', this.hideMenu);
    window.removeEventListener('resize', this.resizeCanvas);
  }

  hideMenu(ev: any) {
    this.isMenuOpened = false;
    let isMenuElement = false;
    let isComboboxElement = false;

    const element = document.getElementById(ev.target.id);
    const elementMenu = element?.closest('#menu');

    if (element == null) return;

    if (elementMenu != null && element != undefined) {
      isMenuElement = true;
    }

    if (
      ev.srcElement.className == 'v-list-item__title' ||
      ev.srcElement.className == 'v-list-item__content' ||
      (ev.srcElement.className as string).includes('v-list-item')
    )
      isComboboxElement = true;

    if (ev.path != undefined)
      ev.path.forEach((element: any) => {
        if (element.id == 'menu') isMenuElement = true;
      });
    if (!isMenuElement && !isComboboxElement) document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  hideMenuElement() {
    document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  private isMenuOpened: boolean = false;
  get showTooltip() {
    return !this.isMenuOpened;
  }

  saveMarkup() {
    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      if (this.boxes[i].isSelected) {
        this.boxes[i].fieldName = this.editFieldName;
        this.boxes[i].isMarker = this.isMarkup;
        this.boxes[i].markerText = this.MarkerText;
        this.hideMenuElement();
        this.updateInDatabase();
      }
    }
  }

  cancelMarkup() {
    this.hideMenuElement();
  }

  deleteMarkup() {
    this.deleteSelectedMarkup();
    this.updateInDatabase();
    this.hideMenuElement();
  }

  resizeCanvas() {
    if (this.isTemplateMode) {
      const zz1 = document.getElementById('myCanvas');
      if (zz1 != null) {
        if (this.mycanvas != null) {
          this.mycanvas!.width = this.canvas.width;
          this.mycanvas!.height = this.canvas.height;
          this.mycanvas!.style.width = this.canvas.style.width;
          this.mycanvas!.style.height = this.canvas.style.height;
        }
      }
    }
  }

  @Watch('isTemplateMode')
  templating() {
    if (this.isTemplateMode) {
      var zz1 = document.getElementById('myCanvas');
      if (zz1 != null) {
        zz1!.remove();
      }

      //var zz = document.getElementsByClassName('textLayer');
      //zz[0].setAttribute('hidden', 'true'); //setAttribute('style', 'display:none');

      this.init3();
    } else {
      var zz1 = document.getElementById('myCanvas');
      if (zz1 != null) {
        zz1!.remove();
      }

      //var zz = document.getElementsByClassName('textLayer');
      //zz[0].setAttribute('hidden', 'false');
    }
  }

  //colorize log output
  //console.log('📕: error message');
  //console.log('📙: warning message');
  //console.log('📗: ok status message');
  //console.log('📘: action message');
  //console.log('📓: canceled status message');
  //console.log('📔: Or anything you like and want to recognize immediately by color');

  async openHandler(pdfApp: any) {
    //https://github.com/sandanat/vue-pdf-app/blob/06107045276b4e2f61fb1b05aa98f87eb42cac7a/src/pdfjs-dist/lib/test/unit/pdf_find_controller_spec.js
    //https://github.com/ocrmypdf/OCRmyPDF
    const words = this.documentsSearchPayload.chipDataArray.map((element: any) => element.searchWord);
    const searchWords = words.join(' ');

    if (searchWords.length > 0) {
      //console.log('📗', searchWords);
      pdfApp.pdfViewer.findController.executeCommand('find', {
        query: searchWords,
        caseSensitive: false,
        entireWord: false,
        phraseSearch: false,
        findPrevious: undefined,
        highlightAll: true,
      });
    }

    const info = await pdfApp.pdfDocument.getMetadata().catch(console.error.bind(console));
    if (!info) return;
    const props = Object.keys(info.info);
    props.forEach((prop) => {
      const obj = {
        name: prop,
        value: info.info[prop],
      };
    });

    pdfApp.pdfDocument.getPage(1).then((page: any) => {
      if (page.rotate == 270) {
        this.originalWidth = page.view[3];
        this.originalHeight = page.view[2];
      } else {
        this.originalWidth = page.view[2];
        this.originalHeight = page.view[3];
      }
      console.log('original size: ' + this.originalWidth + ',' + this.originalHeight);
      this.image150DPIWidth = Math.floor(this.originalWidth * 2.084);
      this.image150DPIHeight = Math.floor(this.originalHeight * 2.084);
    });
  }

  private originalWidth: number = 0;
  private image150DPIWidth: number = 0;
  private originalHeight: number = 0;
  private image150DPIHeight: number = 0;
  private scale: number = 1;
  private scaleCanvasTo150DPI: number = 1;

  private convertScaleName(scale: string) {
    //1 - automatic = auto, 2 - original_size = page-actual, 3 - page_size = page-fit, 4 - page_width = page-width
    switch (scale) {
      case '1':
        return 'auto';
      case '2':
        return 'page-actual';
      case '3':
        return 'page-fit';
      case '4':
        return 'page-width';
    }
  }

  async setEvents() {
    if (this.user?.pdfZoom) this.pdfApp.pdfViewer.currentScaleValue = this.convertScaleName(this.user.pdfZoom);

    const scaleSelect: any = document.getElementById('scaleSelect');
    scaleSelect.addEventListener(
      'change',
      (e: any) => {
        this.scale = this.pdfApp.pdfViewer.currentScaleValue;
        console.log(this.scale);
      },
      false
    );
  }

  private pdfApp: any;

  afterCreatedHandler(pdfApp: any) {
    console.log('afterCreatedHandler');
    this.pdfApp = pdfApp;
  }

  pagesRendered(pdfApp: any) {
    console.log('pagesRendered');
    this.setEvents();
  }

  init3() {
    this.canvas = <HTMLCanvasElement>document.querySelector('[aria-label="Page 1"]');
    this.canvasWrapper = this.canvas.closest('.canvasWrapper');
    this.canvasPage = this.canvas.closest('.page');
    this.ctx = this.canvas.getContext('2d');
    this.mycanvas = document.createElement('canvas');
    this.mycanvas.id = 'myCanvas';
    this.mycanvas.width = this.canvas.width;
    this.mycanvas.height = this.canvas.height;
    this.mycanvas.style.width = this.canvas.style.width;
    this.mycanvas.style.height = this.canvas.style.height;
    this.mycanvas.style.zIndex = '999';
    this.mycanvas.style.position = 'absolute';

    this.mycanvas.onmousedown = this.myDown;
    this.mycanvas.ondblclick = this.myDblClick;
    this.mycanvas.onmousemove = this.myMove;
    this.mycanvas.onmouseup = this.myUp;
    this.mycanvas.onkeyup = this.myKeyHandle;
    this.mycanvas.addEventListener('contextmenu', this.myContextmenu, false);
    this.mycanvas.addEventListener('wheel', this.hideMenuElement, false);

    this.myctx = this.mycanvas.getContext('2d');
    this.myctx.globalAlpha = 0.5;

    const zzz = document.querySelector('.page[data-page-number="1"] > div > canvas');
    zzz!.before(this.mycanvas);

    console.log('150DPI size: ' + this.image150DPIWidth + ',' + this.image150DPIHeight);
    console.log('canvas size: ' + this.mycanvas.width + ',' + this.mycanvas.height);
    this.scaleCanvasTo150DPI = this.image150DPIWidth / this.mycanvas.width;
    console.log('scaleCanvasTo150DPI: ' + this.scaleCanvasTo150DPI);
    this.actionSetTemplateMarkupsScale(this.scaleCanvasTo150DPI);

    // make mainDraw() fire every INTERVAL milliseconds
    setInterval(this.mainDraw, this.INTERVAL);

    // set up the selection handle boxes
    for (let i = 0; i < 8; i++) {
      const rect = new Rectangle('', '', 0, 0, 0, 1, 1, '', '', false, '');
      this.selectionHandles.push(rect);
    }

    this.loadFromDatabase();
  }

  isHit(shape: any, x: any, y: any) {
    if (shape.constructor.name === 'Arc') {
      const dx = shape.x - x;
      const dy = shape.y - y;
      if (dx * dx + dy * dy < shape.radius * shape.radius) {
        return true;
      }
    } else {
      if (x > shape.x && y > shape.y && x < shape.x + shape.width && y < shape.y + shape.height) {
        return true;
      }
    }

    return false;
  }

  // https://stackoverflow.com/questions/17130395/real-mouse-position-in-canvas
  getMousePos(canvas: any, evt: any) {
    const rect = canvas.getBoundingClientRect();
    return {
      x: ((evt.clientX - rect.left) / (rect.right - rect.left)) * canvas.width,
      y: ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height,
    };
  }

  myKeyHandle(e: any) {
    if (e.key == 'Delete') {
      this.deleteSelectedMarkup();
    }
  }

  deleteSelectedMarkup() {
    let positionToDelete = -1;
    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      if (this.boxes[i].isSelected) {
        positionToDelete = i;
      }
    }
    this.boxes.splice(positionToDelete, 1);
  }

  myDown(e: any) {
    // focus to handle keypress
    this.mycanvas.setAttribute('tabindex', '0');
    this.mycanvas.focus();

    const pos = this.getMousePos(this.mycanvas, e);
    this.startX = pos.x;
    this.startY = pos.y;
    //we are over a selection box
    if (this.expectResize !== -1) {
      this.isResizeDrag = true;
      return;
    }

    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      if (this.isHit(this.boxes[i], pos.x, pos.y)) {
        this.boxes[i].isSelected = true;
        this.boxes[i].isDragged = true;
      } else {
        this.boxes[i].isSelected = false;
        this.boxes[i].isDragged = false;
      }
    }
  }

  myUp() {
    this.isResizeDrag = false;
    this.expectResize = -1;
    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      if (this.boxes[i].isSelected) {
        this.boxes[i].isDragged = false;
      }
    }
    this.update();
  }

  myDblClick(e: any) {
    const pos = this.getMousePos(this.mycanvas, e);
    let zz = Math.max.apply(
      Math,
      this.boxes.map(function (o: any) {
        return o.name;
      })
    );
    if (!isFinite(zz)) zz = 0;
    const rectangle = new Rectangle('', this.documentId, 0, pos.x, pos.y, 60, 20, '', '' + (zz + 1), false, '');
    this.boxes.push(rectangle);
    this.update();
  }

  myContextmenu(e: any) {
    e.preventDefault();
    if (e.target === this.mycanvas) {
      // if we are on empty place of the stage we will do nothing
      //return;
    }
    const pos = this.getMousePos(this.mycanvas, e);
    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      if (this.isHit(this.boxes[i], pos.x, pos.y)) {
        this.editFieldName = this.boxes[i].fieldName;
        this.isMarkup = this.boxes[i].isMarker;
        this.MarkerText = this.boxes[i].markerText;
        const menuHeight = 272;
        let positionY = e.pageY - 44;
        if (positionY + menuHeight > window.innerHeight) {
          positionY = window.innerHeight - 44 - menuHeight - 10;
        }
        document.getElementById('menu')?.style.setProperty('top', positionY + 'px');
        document.getElementById('menu')?.style.setProperty('left', e.pageX + 4 + 'px');
        document.getElementById('menu')?.style.setProperty('display', 'initial');
        document.getElementById('menu')?.style.setProperty('z-index', '9999');
        this.isMenuOpened = true;
      } else {
      }
    }
  }

  saveIsMarker(value: any) {
    //https://localhost:3003/api/ocr/RecognizeTextInBox/dc6efb27-0743-43c2-a5cf-3b73fcfe58e0/51/142/140/48
    if (value) {
      const l = this.boxes.length;
      for (let i = 0; i < l; i++) {
        if (this.boxes[i].isSelected == true)
          this.actionGetLastRecognizedTextInBox({
            id: this.fileId,
            x: Math.floor(this.boxes[i].x * this.Scale),
            y: Math.floor(this.boxes[i].y * this.Scale),
            width: Math.floor(this.boxes[i].width * this.Scale),
            height: Math.floor(this.boxes[i].height * this.Scale),
          }).then(() => (this.MarkerText = this.getterGetLastRecognizedTextInBox));
      }
    }
  }

  loadFromDatabase() {
    this.actionGetTemplateMarkups({ documentId: this.documentId }).then(() => {
      this.getTemplateMarkups.items.forEach((element: TemplateMarkup) => {
        element.x = element.x / this.scaleCanvasTo150DPI;
        element.y = element.y / this.scaleCanvasTo150DPI;
        element.width = element.width / this.scaleCanvasTo150DPI;
        element.height = element.height / this.scaleCanvasTo150DPI;
      });

      this.boxes = [];
      const l = this.getTemplateMarkups.items.length;
      for (let i = 0; i < l; i++) {
        const rectangle = new Rectangle(
          this.getTemplateMarkups.items[i].id,
          this.getTemplateMarkups.items[i].userFileId,
          this.getTemplateMarkups.items[i].page,
          this.getTemplateMarkups.items[i].x,
          this.getTemplateMarkups.items[i].y,
          this.getTemplateMarkups.items[i].width,
          this.getTemplateMarkups.items[i].height,
          this.getTemplateMarkups.items[i].fieldName,
          this.getTemplateMarkups.items[i].name,
          this.getTemplateMarkups.items[i].isMarker,
          this.getTemplateMarkups.items[i].markerText
        );
        this.boxes.push(rectangle);
      }
      this.update();
    });
  }

  update() {
    const l = this.boxes.length;
    for (var i = 0; i < l; i++) {
      const index = this.getTemplateMarkups.items.findIndex((item: TemplateMarkup) => item.name === this.boxes[i].name);
      if (index >= 0) {
        this.boxes[i].fieldName = this.getTemplateMarkups.items[index].fieldName;
        this.boxes[i].markerText = this.getTemplateMarkups.items[index].markerText;
        this.boxes[i].isMarker = this.getTemplateMarkups.items[index].isMarker;
      }
    }
    this.updateInDatabase();
  }

  updateInDatabase() {
    this.actionClearTemplateMarkup();
    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      const record = templateMarkup.parse({});
      record.id = this.boxes[i].id;
      record.userFileId = this.boxes[i].documentId;
      record.page = this.boxes[i].page;
      record.x = this.boxes[i].x;
      record.y = this.boxes[i].y;
      record.width = this.boxes[i].width;
      record.height = this.boxes[i].height;
      record.fieldName = this.boxes[i].fieldName;
      record.name = this.boxes[i].name;
      record.isMarker = this.boxes[i].isMarker;
      record.markerText = this.boxes[i].markerText;
      record.isSelected = this.boxes[i].isSelected;
      this.actionAddTemplateMarkup(record);
    }
  }

  private currentTooltip: string = '';
  private isTooltipMouseOver: boolean = false;
  myMove(e: any) {
    this.currentTooltip = '';
    this.isTooltipMouseOver = false;
    const pos = this.getMousePos(this.mycanvas, e);

    const dx = pos.x - this.startX;
    const dy = pos.y - this.startY;
    this.startX = pos.x;
    this.startY = pos.y;

    const l = this.boxes.length;
    for (var i = 0; i < l; i++) {
      if (this.isHit(this.boxes[i], pos.x, pos.y)) {
        this.isTooltipMouseOver = true;
        this.currentTooltip = this.boxes[i].isMarker
          ? this.$t('tooltip.marker') + ' - ' + this.boxes[i].name + ': ' + this.boxes[i].markerText
          : this.$t('tooltip.field') +
            ' - ' +
            this.boxes[i].name +
            ': ' +
            this.FieldNameFromid(this.boxes[i].fieldName);
        break;
      } else {
        this.currentTooltip = '';
      }
    }

    for (var i = 0; i < l; i++) {
      if (this.boxes[i].isSelected) {
        if (this.boxes[i].isDragged) {
          this.boxes[i].x += dx;
          this.boxes[i].y += dy;
        } else if (this.isResizeDrag) {
          this.boxes[i].width += dx;
          this.boxes[i].height += dy;
        }
      }
    }

    if (this.isTooltipMouseOver) {
      const tooltipHeight = 52;
      let positionY = e.pageY - 44;
      if (positionY + tooltipHeight > window.innerHeight) {
        positionY = window.innerHeight - 44 - tooltipHeight - 10;
      }
      document.getElementById('tooltip')?.style.setProperty('top', positionY + 'px');
      document.getElementById('tooltip')?.style.setProperty('left', e.pageX + 4 + 'px');
      document.getElementById('tooltip')?.style.setProperty('display', 'initial');
      document.getElementById('tooltip')?.style.setProperty('z-index', '99999');
    } else {
      document.getElementById('tooltip')?.style.setProperty('display', 'none');
    }

    // if there's a selection see if we grabbed one of the selection handles
    if (!this.isResizeDrag) {
      for (var i = 0; i < 8; i++) {
        // 0  1  2
        // 3     4
        // 5  6  7

        const cur = this.selectionHandles[i];
        // we dont need to use the ghost context because
        // selection handles will always be rectangles
        if (
          pos.x >= cur.x &&
          pos.x <= cur.x + this.mySelBoxSize &&
          pos.y >= cur.y &&
          pos.y <= cur.y + this.mySelBoxSize
        ) {
          // we found one!
          this.expectResize = i;
          //this.invalidate();

          switch (i) {
            case 0:
              this.mycanvas.style.cursor = 'nw-resize';
              break;
            case 1:
              this.mycanvas.style.cursor = 'n-resize';
              break;
            case 2:
              this.mycanvas.style.cursor = 'ne-resize';
              break;
            case 3:
              this.mycanvas.style.cursor = 'w-resize';
              break;
            case 4:
              this.mycanvas.style.cursor = 'e-resize';
              break;
            case 5:
              this.mycanvas.style.cursor = 'sw-resize';
              break;
            case 6:
              this.mycanvas.style.cursor = 's-resize';
              break;
            case 7:
              this.mycanvas.style.cursor = 'se-resize';
              break;
          }
          return;
        }
      }
      // not over a selection box, return to normal
      this.isResizeDrag = false;
      this.expectResize = -1;
      this.mycanvas.style.cursor = 'auto';
    }
  }

  clear() {
    this.myctx.clearRect(0, 0, this.mycanvas.width, this.mycanvas.height);
  }

  setSelectedFromRightMenu() {
    const l = this.boxes.length;
    for (var i = 0; i < l; i++) {
      const index = this.getTemplateMarkups.items.findIndex((item: TemplateMarkup) => item.name === this.boxes[i].name);
      if (index >= 0) {
        this.boxes[i].isSelected = this.getTemplateMarkups.items[index].isSelected;
        this.boxes[i].isDragged = false;
      }
    }
  }

  @Watch('getterGetTemplateMarkups', { immediate: true, deep: true })
  onSelectedChange(newVal: any, oldVal: any) {
    this.setSelectedFromRightMenu();
  }

  mainDraw() {
    this.clear();
    const l = this.boxes.length;
    for (let i = 0; i < l; i++) {
      if (this.boxes[i].isSelected) {
        // draw the boxes
        const half = this.mySelBoxSize / 2;

        // 0  1  2
        // 3     4
        // 5  6  7

        // top left, middle, right
        this.selectionHandles[0].x = this.boxes[i].x - half;
        this.selectionHandles[0].y = this.boxes[i].y - half;

        this.selectionHandles[1].x = this.boxes[i].x + this.boxes[i].width / 2 - half;
        this.selectionHandles[1].y = this.boxes[i].y - half;

        this.selectionHandles[2].x = this.boxes[i].x + this.boxes[i].width - half;
        this.selectionHandles[2].y = this.boxes[i].y - half;

        //middle left
        this.selectionHandles[3].x = this.boxes[i].x - half;
        this.selectionHandles[3].y = this.boxes[i].y + this.boxes[i].height / 2 - half;

        //middle right
        this.selectionHandles[4].x = this.boxes[i].x + this.boxes[i].width - half;
        this.selectionHandles[4].y = this.boxes[i].y + this.boxes[i].height / 2 - half;

        //bottom left, middle, right
        this.selectionHandles[6].x = this.boxes[i].x + this.boxes[i].width / 2 - half;
        this.selectionHandles[6].y = this.boxes[i].y + this.boxes[i].height - half;

        this.selectionHandles[5].x = this.boxes[i].x - half;
        this.selectionHandles[5].y = this.boxes[i].y + this.boxes[i].height - half;

        this.selectionHandles[7].x = this.boxes[i].x + this.boxes[i].width - half;
        this.selectionHandles[7].y = this.boxes[i].y + this.boxes[i].height - half;

        this.boxes[i].selectionHandles = this.selectionHandles;
      }

      this.boxes[i].draw(this.myctx, i); // we used to call drawshape, but now each box draws itself
    }
    if (this.getTemplateMarkups.items.length == 0) {
      this.update();
    }
  }

  // vars and consts

  private INTERVAL = 20;

  private canvas: any;
  private canvasWrapper: any;
  private canvasPage: any;
  private ctx: any;

  private mycanvas: any;
  private myctx: any;

  private boxes: any = [];
  private selectionHandles: any = [];

  // The selection color and width. Right now we have a red selection with a small width
  private mySelColor = '#CC0000';
  private mySelWidth = 2;
  private mySelBoxColor = 'darkred'; // New for selection boxes
  private mySelBoxSize = 6;

  private isDrag = false;
  private isResizeDrag = false;
  private expectResize = -1; // New, will save the # of the selection handle if the mouse is over one.

  private startX = 0;
  private startY = 0;
}

export class Rectangle {
  id: string;
  documentId: string;
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
  fieldName: string;
  name: string;
  isMarker: boolean;
  markerText: string;
  isSelected: boolean;
  isDragged: boolean;
  selectionHandles: any = [];
  constructor(
    id: string,
    documentId: string,
    page: number,
    x: number,
    y: number,
    width: number,
    height: number,
    fieldName: string,
    name: string,
    isMarker: boolean,
    markerText: string
  ) {
    this.id = id;
    this.documentId = documentId;
    this.page = page;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.fieldName = fieldName;
    this.name = name;
    this.isMarker = isMarker;
    this.markerText = markerText;
    this.isSelected = false;
    this.isDragged = false;
  }
  draw(ctx: any, i: number) {
    ctx.save();

    ctx.beginPath();
    ctx.rect(this.x, this.y, this.width, this.height);
    ctx.fillStyle = 'rgba(25, 25, 255, 0.1)';
    if (this.isMarker) ctx.fillStyle = 'rgba(255, 25, 255, 0.1)';
    ctx.fill();

    //var txt = this.isMarker ? 'Marker-ID' + this.name : 'Region-ID' + this.name;
    //ctx.font = '12pt Arial';
    //ctx.fillStyle = 'black';
    //ctx.fillText(txt, this.x, this.y - 2);

    if (this.isSelected) {
      ctx.strokeStyle = 'darkred';
      ctx.strokeRect(this.x, this.y, this.width, this.height);
      for (var i = 0; i < 8; i++) {
        const cur = this.selectionHandles[i];
        ctx.fillStyle = 'black';
        ctx.fillRect(cur.x, cur.y, 6, 6);
      }
    }

    ctx.closePath();

    ctx.restore();
  }
}
